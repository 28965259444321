
import "../pages/styles/HomeStyle.css";
import React, { useState,useEffect } from 'react';
import { ReactComponent as MyIcon } from '../icons/fiverr.svg';
import fiverr from '../icons/fiverr.png';
import profilePic from '../images/profile1.jpeg';
import AutoTypewriter from "../components/AutoTypewriter";
import { SocialIcon } from 'react-social-icons';
import { About } from "./About";
import { Education } from "./Education";
import { Projects } from "./Projects";
import { ContactUs } from "./ContactUs";
import { Services } from "./Services";
import { useParams } from "react-router-dom";

import PuffLoader				 from "react-spinners/PuffLoader";

export const Home = () => {
    const {section} = useParams();
    const [isHovered, setIsHovered] = useState(false);
    const [projectClicked, setProjectClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleIconClick=(url)=>{
      window.open(url,'_blank');
    }

    const handleButtonClick = () => {
      const email = 'dan.malshan@gmail.com';
      const subject = 'Hello Danusha';
      const body = 'This is the email body.';
  
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    };

    useEffect(() => {
      // const urlParams = new URLSearchParams(window.location.search);
      // const boolParam = urlParams.get('bool');
      // console.log(`boolparam${boolParam}`)
      // setIsLoading(boolParam);
    // const section_id = urlParams.get('sectionId');
       
    //     const sectionElement = document.getElementById(section_id);
        
    //     if (sectionElement) {
    //       sectionElement.scrollIntoView({ behavior: 'smooth' });
    //     }

        const loadData = async () => {
            
            await new Promise((resolve) => setTimeout(resolve, 2000));
        
           
            setIsLoading(false);
          };
        
          loadData();
      }, []);

    return (
        <div>
            {
                isLoading?(
                    <div className="spinner">
                      <PuffLoader				 	size={120} color="#F7D060" />
                    </div>
                  ) :(
                 <div >
            

            <section className="home-top" id="home">
            <div className="pro-pic-container">
                    <img src={profilePic} className="profile-pic"></img>
                    <div className="overlay"></div>
                </div>

                <div className="name">
                    <h1>Danusha Malshan</h1>
                </div>
                
                <div className="description">


                    <div className="home-title">
                        <h1 className="static-txt">I'm a</h1>
                        <h1 className="dynamic-txt">
                            <AutoTypewriter texts={['Mobile App Developer',' Freelancer']} />
                        </h1>

                    </div>
                    
                    


                </div>
                <div className="icon-container">
                       <SocialIcon network="github" className="icon" bgColor="#e6b147" style={{ width: '3.6rem', height: '3.6rem' }} onClick={() => handleIconClick('https://github.com/danushaMalshan')}/>
                       <SocialIcon network="linkedin" className="icon" bgColor="#e6b147"  style={{ width: '3.6rem', height: '3.6rem' }} onClick={() => handleIconClick('https://www.linkedin.com/in/danusha-malshan/')}/>
                       <SocialIcon network="email" className="icon" bgColor="#e6b147"  style={{ width: '3.6rem', height: '3.6rem' }} onClick={handleButtonClick}/>
                       {/* <SocialIcon network="stackoverflow" className="icon" bgColor="#e6b147"  style={{ width: '3.6rem', height: '3.6rem' }} onClick={() => handleIconClick('https://stackoverflow.com/users/16552975/danusha-malshan')}/> */}
                       <MyIcon onClick={()=>handleIconClick('https://www.fiverr.com/s/LyAkLo')}  className='fiverr-icon-home' style={{height:'3.8rem',width:'3.8rem' }}/>
                       
{/*                       
                   <Github className="icon"/>
                   <Linkedin className="icon"/>
                   <Github className="icon"/>
                   <Github className="icon"/> */}
                      
                       
                       
                </div>
            </section>

           <About/>
           
           
           <Education/>
           <Services/>
           <Projects />
           <ContactUs/>
            
        </div>
                  )
            }
        </div>
        

    )
}