import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css";
import '../pages/styles/ProjectAllDetailsStyle.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function ImageSlider (props){
  const settings = {
    showStatus: false,
    showIndicators: false,
    showArrows: false,
    autoPlay: true,
    interval: 5000,
    infiniteLoop: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    thumbWidth: 0,
    showThumbs: false,
    
  };

  // const settings = {
  //   showStatus: false,
  //   showIndicators: true,
  //   showArrows: true,
  //   autoPlay: true,
  //   interval: 5000,
  //   infiniteLoop: true,
  //   stopOnHover: true,
  //   swipeable: true,
  //   dynamicHeight: false,
  //   emulateTouch: true,
  //   showThumbs: false,
  //   showArrows: true,
  //   showStatus: false,
  //   renderThumbs: () => {},
  //   centerMode: true,
  //   centerSlidePercentage: 33.33,
  //   selectedItem: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         centerMode: true,
  //         centerSlidePercentage: 33.33,
  //         arrows: true,
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         centerMode: true,
  //         centerSlidePercentage: 33.33,
  //         arrows: true,
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         centerMode: true,
  //         centerSlidePercentage: 33.33,
  //         arrows: true,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  console.log(props.other_images[0].image);
      return (
        
        <Carousel {...settings} className="carousel-image" >
           
            {
              props.other_images.map((imageDetails)=>{
                     return (
                      <div>
                      <img src={imageDetails.image} className="img-container" />
                     
                  </div>
                     )
              })

            }
            
        </Carousel>

        
    );
    }
  

  export default ImageSlider;