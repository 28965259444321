import '../pages/styles/ProjectAllDetailsStyle.css'

import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGithub} from '@fortawesome/free-brands-svg-icons'


import { useLocation, Link } from "react-router-dom";
import ImageSlider from '../components/Slider';

import PuffLoader from "react-spinners/PuffLoader";

export const ProjectsAllDetails =(props)=>{
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        

        const loadData = async () => {
            
            await new Promise((resolve) => setTimeout(resolve, 2000));
        
           
            setIsLoading(false);
          };
        
          loadData();
      }, []);

      const handleIconClick=(url)=>{
        window.open(url,'_blank');
      }
    return (
        <div>
            {
                isLoading?(
                    <div className="spinner">
                      <PuffLoader				 	size={120} color="#F7D060" />
                    </div>
                  ) :(<div className='project-details-body'>
                  <div className='project-details-head'>
                      
                      <img className='project-cover' src={location.state.cover_image}/>
                     
                     <div className='project-details-title'>
                         <h1>{location.state.name}</h1>
                          
                          <div className='timeline-tech'>
                            
                             {location.state.tech_stacks.platform!=''? <p>Platform : <span className='decorate-txt'>{location.state.tech_stacks.platform}</span></p> :<></>}
                             {location.state.tech_stacks.language!=''?  <p>Language : <span className='decorate-txt'>{location.state.tech_stacks.language}</span></p>:<></>}
                             {location.state.tech_stacks.text_editor!=''?  <p>Text Editor : <span className='decorate-txt'>{location.state.tech_stacks.text_editor}</span></p>:<></>}
                             {location.state.tech_stacks.backend!=''?  <p>Backend : <span className='decorate-txt'>{location.state.tech_stacks.backend}</span></p>:<></>}
                             {location.state.tech_stacks.dbms!=''?  <p>DBMS : <span className='decorate-txt'>{location.state.tech_stacks.dbms}</span></p>:<></>}
                             {location.state.tech_stacks.github!=null?   <p>Source Code : <span onClick={()=>handleIconClick(`${location.state.github}`)} className='decorate-txt github'>GitHub <FontAwesomeIcon icon={faGithub} size='1x' style={{ color: '#F7D060' }}/></span></p>:<></>}
                              
                            
             
             
                           </div>
                           
                     </div>
                  </div>
      
      
                  <div className='project-details-description'>
                     <div className='paragrah'>
                     {location.state.long_description.split('#').map((line, index) => (
          <p key={index}>
          {line}
          <br/>
          </p>
       
      ))}
                     </div>
                 
                     
                   {location.state.other_images.length!=0?<div className='carousel'>
                          {/* <Slider img={state.img} /> */}
                          <ImageSlider other_images={location.state.other_images}/>
                      </div>:<></>} 
                  </div>
                  
              </div>)
            }
        </div>
        
        
    )
}