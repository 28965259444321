import '../pages/styles/ProjectsStyle.css'

import { SocialIcon } from 'react-social-icons';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGithub} from '@fortawesome/free-brands-svg-icons'

import detailsData from '../res/ProjectsDetails.js'
import ProjectCard from '../components/ProjectCard';
export const Projects =()=>{
    
    return (
        <section className='top' id='projects'>
         <div className='project-title'>

                <h3>Hey here are some of my projects</h3>
          </div>
           <div className="project-body">
            {
              detailsData.map((project,index)=>{
                     return (
                        <ProjectCard key={index} tech_stacks={project.tech_stacks} github={project.github} name={project.name} cover_image={project.cover_image} other_images={project.other_images} short_description={project.short_description} long_description={project.long_description}/>
                     )
              })

            }
            
    
        </div>
        </section>
        
    )
}