import "../pages/styles/AboutStyle.css";


export const About =()=>{

    return (
        
    <section className="about-container" id="about">
        <div className="about" >
            
               <h1 className="title" >A little bit about me</h1>
              <p>Hey There,  I am Danusha  Malshan. Since beginning my journey as a software developer over 2 years ago, I've done remote work for clients worldwide and helped bring people's ideas to the world. I'm quietly confident, naturally curious, and spend time to exploring and experimenting with new technologies. Do you have any ideas? Do you want to bring your vision to the world? Let's work together</p>
           
        </div>
        </section>
        
    )
}