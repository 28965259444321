import '../pages/styles/ProjectsStyle.css'

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGithub} from '@fortawesome/free-brands-svg-icons'
import { Link} from 'react-router-dom';
function ProjectCard(props){
   
    const handleIconClick=(url)=>{
        window.open(url,'_blank');
      }
  
    return (
        <div className='container'>
            <div className='project-container'>
                  <div className='front' style={{backgroundImage:`url(${props.cover_image})`,backgroundSize: 'cover', backgroundPosition: 'center',
                 backgroundRepeat: 'no-repeat'}}>
                       <h2 className='front-title'>{props.name}</h2>
                  </div>

                  <div className='back'>
                          <div className='content'>
                             <p className='project-description'>{props.short_description}</p>
                            
                          </div>

                          <div className="buttons">
                          {
                           (props.github==null||props.github=='')?<></> :<FontAwesomeIcon onClick={()=>handleIconClick(props.github)}  className='project-card-github' icon={faGithub} size='3x' />
                          }
                          <Link className='more-btn' to='/project_details' state={{name:props.name,cover_image:props.cover_image,other_images:props.other_images,long_description:props.long_description,tech_stacks:props.tech_stacks,github:props.github}} >More</Link>
                       
                       </div>
                            
                              
                     
                          
                  </div>
             </div>
            </div>
    )
}

export default ProjectCard;