import '../pages/styles/EducationStyle.css'
import { VerticalTimeline,VerticalTimelineElement } from 'react-vertical-timeline-component'
import TimeLine from '../components/TimeLine';
import 'react-vertical-timeline-component/style.min.css';

export const Education =()=>{
   const TimeLineData=[

    {
        date:'2022 October - Present',
        tilte:'Software Developer',
        subtitle:'',
        position:'right',
        description:'Techart Solutions - Part Time',
        tech_stack:['Flutter','Node.js','Dart','MySql','Php']
    },
    {
        date:'2022 October - Present',
        tilte:'Bachelor of Information Technology',
        subtitle:'',
        description:'University of Colombo School of Computing',
        position:'left',
       
    },
    
    {
        date:'2022 March - Present',
        tilte:'Freelance Mobile Apps Developer',
        subtitle:'',
        position:'right',
        description:'Fiverr - Part Time',
        tech_stack:['Flutter','Node.js','Dart','MySql']
    },
    {
        date:'2022 January - Present',
        tilte:'Mobile Apps Developer',
        subtitle:'',
        position:'right',
        description:'LK Coders - Full Time',
        tech_stack:['Flutter','Node.js','Dart','MySql','Php']
    },
    {
        date:'2017 June - 2019 August',
        tilte:'Advanced Level',
        subtitle:'3Cs - Physical Science stream',
        position:'left',
        description:'Ananda College Colombo 10',
        
    },
    
   
   ]
      
    return (
        <section className="education-body" id='education'>
            <div className='heading'>
                <h1 className='education'>Education</h1>
                <h1 className='experience'>Experience</h1>
            </div>
            <div className='heading'>
                <h1 className='education-mobile'>Education & Experience</h1>
                
            </div>

            
            <VerticalTimeline>
             { TimeLineData.map((data,index)=>{
                return(
                    
                        <TimeLine key={index} title={data.tilte} subtitle={data.subtitle} date={data.date} description={data.description} position={data.position} tech_stack={data.tech_stack}/>
                  
                    
                )
               })}
            
            </VerticalTimeline>
        </section>
    )
}