import React, { useState } from 'react';
import Typewriter from 'typewriter-effect';

const AutoTypewriter = ({ texts, delay = 2000 }) => {
 return (
    <Typewriter
      options={{
        strings: texts,
        autoStart: true,
        loop: true,
      }}
      
    />
  );
};

export default AutoTypewriter;
