

const detailsData=[
    {
        name:'Lucky Touch',
        short_description:"Lucky Touch is a project that I have built according to a Fiverr client's reqvirenments. Users can buy tickets using USDT, chat with the admin, users can see their bought ticket status and many more features available on this app. Also, the admin can manage all the features like selecting winners, adding round, adding tickets and many more.",
        long_description:"Lucky Touch is a project that I have built according to a Fiverr client's reqvirenments. it was a lottery app where users can buy tickets with USDT.# Furthermore, users can manage their profiles, see user-bought tickets and status, see winners and previous round winners, chat with the admin and many more. also, the admin can see all analytics, remove users, manage rounds, manage tickets, select winners and many other features.# I have used node.js for a backend server and flutter for an app build but the biggest challenge is implementing crypto payment for buying tickets.so I researched for a couple of days and finally, I choose coinbase commerce  API for a crypto payment then I was able to do crypto payments and track all of these payments that are pending, expired or successful. when payment is the successful user can use their ticket.#Client satisfaction is my best pleasure as a freelancer. after completing a project He was delighted and was kind enough to leave a review with a 5-star rating.",
        github:'https://github.com/danushaMalshan/lucky_touch.git',
        cover_image:'https://danncode.com/portfolio/images/lucky_touch/cover.jpg',
        other_images:[
            {id:'1',image:'https://danncode.com/portfolio/images/lucky_touch/1.webp'},
            {id:'2',image:'https://danncode.com/portfolio/images/lucky_touch/2.webp'},
            {id:'3',image:'https://danncode.com/portfolio/images/lucky_touch/3.webp'},
            {id:'4',image:'https://danncode.com/portfolio/images/lucky_touch/4.webp'},
            {id:'5',image:'https://danncode.com/portfolio/images/lucky_touch/5.webp'},
            {id:'6',image:'https://danncode.com/portfolio/images/lucky_touch/6.webp'},
            {id:'7',image:'https://danncode.com/portfolio/images/lucky_touch/7.webp'},
            {id:'8',image:'https://danncode.com/portfolio/images/lucky_touch/8.webp'},
            {id:'9',image:'https://danncode.com/portfolio/images/lucky_touch/9.webp'},
           
        ],
        tech_stacks:{
            platform:'Flutter 3.0.5',
            language:'Dart 2.17.6',
            text_editor:'VS Code',
            backend:'Node.js & Express',
            dbms:'MySQL',
            
        }

        
        
    },

    {
        name:'Together',
        short_description:"Together is a project that we have built for the Codesprin 07 inter-university hackathon. It was an app that Users can easily find events, add reminders, book tickets and many other features. Our team have to complete this in less than a month. Finally, out of 300 teams, our team's project was selected among the top 10 builds.",
        long_description:"\"Together\" is an application that proves to be helpful for individuals seeking enjoyable events. Whether it's musical shows, career fairs, seasonal gatherings, or any event of your preference, this app offers a comprehensive selection. Upon signing up, you have the option to choose your favorite categories, enabling the app to find events that align perfectly with your interests. Moreover, if you happen to be an event organizer, you have the opportunity to effortlessly publish your event with all the essential details.#In addition to the aforementioned features, users can effortlessly set reminders for events they intend to attend, and the app will handle the remainder of the process. Furthermore, this application will provide comprehensive event details, allowing users to conveniently book tickets directly through the app. We are eager to publish this app in the near future.",
        github:'https://github.com/danushaMalshan/codesprin7-bugzfree.git',
        cover_image:'https://danncode.com/portfolio/images/together/cover.webp',
        other_images:[
            {id:'1',image:'https://danncode.com/portfolio/images/together/1.webp'},
            {id:'2',image:'https://danncode.com/portfolio/images/together/2.webp'},
            {id:'3',image:'https://danncode.com/portfolio/images/together/3.webp'},
            {id:'4',image:'https://danncode.com/portfolio/images/together/4.webp'},
            {id:'5',image:'https://danncode.com/portfolio/images/together/5.webp'},
            {id:'6',image:'https://danncode.com/portfolio/images/together/6.webp'},
            {id:'7',image:'https://danncode.com/portfolio/images/together/7.webp'},
            {id:'8',image:'https://danncode.com/portfolio/images/together/8.webp'},
            {id:'9',image:'https://danncode.com/portfolio/images/together/9.webp'},
          
           
        ],
        tech_stacks:{
            platform:'Flutter 3.0.5',
            language:'Dart 2.17.6',
            text_editor:'VS Code',
            backend:'Firebase',
            dbms:'',
            
        }

        
        
    },

    {
        name:'Cget',
        short_description:"This app has been developed for demonstrating a smart home-controlling system. For further details, click on 'More'",
        long_description:"This app was developed while I was working at Techart Solutions. Cget is designed for users who wish to control their homes in a smart manner. When users control devices such as fans, lights, or other appliances, the database updates in real-time. The ESP32 is then able to read these values and control the fans, lights, and other devices based on the received inputs.#Furthermore, the ESP32 will gather crucial data such as temperature and humidity. This project has been developed for demonstration purposes. If you are interested in this endeavor, we can collaborate to customize the app based on your specific requirements.",
        github:'https://github.com/danushaMalshan/iot_remote.git',
        cover_image:'https://danncode.com/portfolio/images/cget/cover.jpg',
        other_images:[
            {id:'1',image:'https://danncode.com/portfolio/images/cget/1.webp'},
           
           
        ],
        tech_stacks:{
            platform:'Flutter 3.0.5',
            language:'Dart 2.17.6',
            text_editor:'VS Code',
            backend:'Firebase',
            dbms:'',
            
        }

        
        
    },
    {
        name:'App der Antworten',
        short_description:"This application has been developed in accordance with the requirements of a German client. The objective of this application is to provide users with solutions to their problems.",
        long_description:"This was an app that helps to users solve their problems with the best solutions. Client satisfaction is my best pleasure as a freelancer. after completing a project He was delighted and was kind enough to leave a review with a 5-star rating. ",
        github:null,
        cover_image:'https://danncode.com/portfolio/images/app_of_question/cover.webp',
        other_images:[
            {id:'1',image:'https://danncode.com/portfolio/images/app_of_question/1.webp'},
              {id:'2',image:'https://danncode.com/portfolio/images/app_of_question/2.webp'},
           
           
        ],
        tech_stacks:{
            platform:'Flutter 3.0.5',
            language:'Dart 2.17.6',
            text_editor:'VS Code',
            backend:'Node.js & Express',
            dbms:'MySql',
            
        }

        
        
    },

    {
        name:'Temp Checker',
        short_description:"The purpose of this site is to get updates about user garden temp and humidity for analysis purposes. For further details tap More.",
        long_description:"Through this website, users are provided with the capability to receive real-time updates regarding the temperature and humidity of their garden. Furthermore, they are empowered to remotely control the garden lights from any location around the globe. In addition to these functionalities, the client expressed a desire to acquire insights into the fluctuations of temperature and humidity at various intervals. Consequently, we have developed a comprehensive website that effectively fulfills all of the client's requirements. By utilizing ESP32 and DHT22 sensors, we were able to continually update and maintain a robust database with the relevant data.",
        github:'https://github.com/danushaMalshan/temp_hum_reader.git',
        cover_image:'https://danncode.com/portfolio/images/temp_checker/cover.jpg',
        other_images:[
            {id:'1',image:'https://danncode.com/portfolio/images/temp_checker/1.webp'},
            
           
           
        ],
        tech_stacks:{
            platform:'',
            language:'PHP ,Html, Js',
            text_editor:'VS Code',
            backend:'',
            dbms:'MySql',
            
        }

        
        
    },

    {
        name:'Job Seeker',
        short_description:"This app was an app that useful for those who seek new jobs and who a looking for a hire them. This is develop according to Canada client requirements.",
        long_description:"This application was developed to cater to individuals seeking new employment opportunities and employers looking to hire suitable candidates. The development of this application was based on the specific requirements outlined by a client from Canada. However, the client required customization and the addition of specific functionalities to their existing application. However, I successfully met their requirements, resulting in their utmost satisfaction, as demonstrated by a five-star rating they generously awarded.",
        github:null,
        cover_image:'https://danncode.com/portfolio/images/job_seeker/cover.webp',
        other_images:[
           
           
           
        ],
        tech_stacks:{
            platform:'Flutter 3.0.5',
            language:'Dart 2.17.6',
            text_editor:'VS Code',
            backend:'',
            dbms:'',
            
        }

        
        
    },

    // {
    //     name:'Serial Communication',
    //     short_description:"Lucky Touch is a project that I have built according to a Fiverr client's reqvirenments. Users can buy tickets using USDT, chat with the admin, users can see their bought ticket status and many more features available on this app. Also, the admin can manage all the features like selecting winners, adding round, adding tickets and many more.",
    //     long_description:"Lucky Touch is a project that I have built according to a Fiverr client's reqvirenments. it was a lottery app where users can buy tickets with USDT.# Furthermore, users can manage their profiles, see user-bought tickets and status, see winners and previous round winners, chat with the admin and many more. also, the admin can see all analytics, remove users, manage rounds, manage tickets, select winners and many other features.# I have used node.js for a backend server and flutter for an app build but the biggest challenge is implementing crypto payment for buying tickets.so I researched for a couple of days and finally, I choose coinbase commerce  API for a crypto payment then I was able to do crypto payments and track all of these payments that are pending, expired or successful. when payment is the successful user can use their ticket.#Client satisfaction is my best pleasure as a freelancer. after completing a project He was delighted and was kind enough to leave a review with a 5-star rating.",
    //     github:'https://github.com/danushaMalshan/lucky_touch.git',
    //     cover_image:'https://danncode.com/portfolio/images/serial_communication/cover.png',
    //     other_images:[
           
           
           
    //     ],
    //     tech_stacks:{
    //         platform:'Flutter 3.0.5',
    //         language:'Dart 2.17.6',
    //         text_editor:'VS Code',
    //         backend:'Node.js & Express',
    //         dbms:'MySql',
            
    //     }

        
        
    // },
    
]

export default detailsData;