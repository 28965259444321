import {faAndroid} from '@fortawesome/free-brands-svg-icons'
import '../pages/styles/ServicesStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as AndroidIcon } from '../icons/android-studio.svg'
import { ReactComponent as WebIcon } from '../icons/computer-repair.svg'
import { ReactComponent as IotIcon } from '../icons/iot.svg'
export const Services=()=>{
    return(
        <section className="services" id="services">
            <div className='services-body'>
                <h1>My Services</h1>
                <div className='services-cards'>
                    <div className='card'>
                    <AndroidIcon className='service_icon'/>
                    <h2>Mobile Apps Development</h2>
                    <p>I will provide various types of responsive mobile apps according to client reqvirenments with attractive user interfaces. most of the time my team will develop apps with Flutter, Node. js, Firebase, and Native Android. </p>
                    </div>

                    <div className='card'>
                    <WebIcon className='service_icon'/>
                    <h2>Web Development</h2>
                    <p>Our web development service offers professional and innovative solutions to help businesses establish a strong online presence. With a team of skilled developers, we specialize in creating dynamic and user-friendly websites tailored to meet specific client needs.</p>
                    </div>

                    <div className='card'>
                    <IotIcon className='service_icon'/>
                    <h2>IOT</h2>
                    <p>We design and develop custom IoT solutions tailored to specific industry needs, including smart homes, smart cities, industrial automation, agriculture, healthcare, and more. Our team of IoT experts works closely with clients to understand their objectives, identify key requirements, and develop scalable and secure IoT architectures.</p>
                    </div>
                  
                </div>
            </div>
        </section>
    );
}



