import '../pages/styles/EducationStyle.css'
import { VerticalTimeline,VerticalTimelineElement } from 'react-vertical-timeline-component'
import {faGithub} from '@fortawesome/free-brands-svg-icons'
import { FaBriefcase,FaGraduationCap  } from 'react-icons/fa';

function TimeLine(props){
return(
<>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgba(0, 0, 0,0.3)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgba(0, 0, 0,0.2)' }}
    date={props.date}
    iconStyle={{ background: '#F7D060', color: '#292826' }} icon={props.position=='left'?<FaGraduationCap />: <FaBriefcase />}
    position={props.position} 
  >
    <h3 className="vertical-timeline-element-title">{props.title}</h3>
    <h4 className="vertical-timeline-element-subtitle">{props.subtitle}</h4>
    <p>
    {props.description}
    </p>
    <div className='timeline-tech'>
      {
           props.tech_stack!=null? props.tech_stack.map((stack)=>{
                return <p className='tech-stack' style={{marginRight:'5px'}}>{stack}</p>
            }):null
        }
       
       
    </div>
  </VerticalTimelineElement>
  
    
</>
)
}

export default TimeLine;