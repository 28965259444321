import {Link} from 'react-router-dom'
import { useEffect,useState } from "react"
import "../components/styles/NavBarStyles.css"
import headerLogo from '../images/DANNCODE.COM (5).png';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import resume from "../pdf/Danusha Malshan.pdf";
export const NavBar=()=>{

  const [selectedItem,setselectedItem]=useState('home');
  const [menuClicked,setMenuStatus]=useState(false);
  const [activeSection, setActiveSection] = useState('home');

  const handleNavClick = sectionId => {
    setMenuStatus(false);
    document.body.style.overflow = 'visible';
    const section = document.getElementById(sectionId);
   
    if(!section){
      window.location.href = `/#${sectionId}`; 

    
    }else{
      const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
    // const offset=window.innerHeight*0.1;
    //  const topOffset=section.getBoundingClientRect().top+window.pageYOffset - offset;
    //  window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
    
  };

  

  const clickMenu= () =>{
    
    setMenuStatus(!menuClicked);
    menuClicked?document.body.style.overflow = 'visible':document.body.style.overflow = 'hidden';
  }

  const handleScroll = () => {
    if (window.pageYOffset > 0 ) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    const sectionElements = Array.from(document.querySelectorAll('section'));

    sectionElements.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.offsetHeight;
      const scrollTop = window.pageYOffset+20;

      if (scrollTop >= sectionTop && scrollTop < sectionBottom) {
        setActiveSection(section.id);
      }
    });
  };

  const openResume=()=>{
    window.open('../pdf/danusha_malshan.pdf');
  }

  
  function scrollToTop(item) {
    setselectedItem(item);
    window.scrollTo(0, 0);
  }
    const [scrolled,setScrolled]=useState(false);
    useEffect(() => {
        // function handleScroll() {
        //   if (window.pageYOffset > 0) {
        //     setScrolled(true);
        //   } else {
        //     setScrolled(false);
        //   }
        // }
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return(
        <div className={scrolled?'header scrolled':'header'}>
            <div className="header-logo">
            <img src={headerLogo} className="header-logo"></img>
            </div>
            {/* <ul className='nav-bar'>
                <li ><Link className={selectedItem=='home'? 'nav-item selected':'nav-item unselected'} to="/" onClick={()=>scrollToTop('home')}>Home</Link></li>
                <li ><Link className={selectedItem=='about'? 'nav-item selected':'nav-item unselected'} to="/about" onClick={()=>scrollToTop('about')}>About</Link></li>
                <li ><Link className={selectedItem=='education'? 'nav-item selected':'nav-item unselected'} to="/education" onClick={()=>scrollToTop('education')}>Education & Experience</Link></li>
                <li ><Link className={selectedItem=='projects'? 'nav-item selected':'nav-item unselected'} to="/projects" onClick={()=>scrollToTop('projects')}>Projects</Link></li>
                <li ><Link className={selectedItem=='contact'? 'nav-item selected':'nav-item unselected'} to="/contact" onClick={()=>scrollToTop('contact')}>Contact</Link></li>
                <li ><Link className='nav-item-resume' to="/contact" onClick={()=>scrollToTop('home')}>Resume</Link></li>
            </ul> */}

            <ul className={menuClicked?'nav-bar-show':'nav-bar'}>
                <li ><Link className={activeSection ==='home'? 'nav-item selected':'nav-item unselected'} onClick={() => handleNavClick('home')} >Home</Link></li>
                <li ><Link className={activeSection==='about'? 'nav-item selected':'nav-item unselected'} onClick={() => handleNavClick('about')}  >About</Link></li>
                <li ><Link className={activeSection==='education'? 'nav-item selected':'nav-item unselected'} onClick={() => handleNavClick('education')}  >Education & Experience</Link></li>
                <li ><Link className={activeSection==='services'? 'nav-item selected':'nav-item unselected'} onClick={() => handleNavClick('services')}  >Services</Link></li>
                <li ><Link className={activeSection==='projects'? 'nav-item selected':'nav-item unselected'} onClick={() => handleNavClick('projects')}  >Projects</Link></li>
                <li ><Link className={activeSection==='contact'? 'nav-item selected':'nav-item unselected'} onClick={() => handleNavClick('contact')}   >Contact</Link></li>
                <li ><a href={resume} target='_blank' className='nav-item-resume' >Resume</a></li>
            </ul>
            {
              menuClicked?<AiOutlineClose className='menu-icon' onClick={clickMenu}/>:<FaBars className='menu-icon' onClick={clickMenu}/>
            }
            
        </div>
    )
}