import '../pages/styles/ContactStyle.css'
import { ReactComponent as MyIcon } from '../icons/icons8-fiverr.svg';
import {useRef} from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
export const ContactUs =()=>{
  const form =useRef()
  const notify = () => toast.success("Email sent successfully");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7mfdwah', 'template_zioqrg2', form.current, 'uogfmPPQP8-eaBSrK')
      .then((result) => {
       notify();
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  const handleIconClick=(url)=>{
    window.open(url,'_blank');
  }
    return (
        <section className="contact-body" id='contact'>
              <h1>Let's Work Together</h1>
              <form onSubmit={sendEmail} ref={form}>
                  <div className='contact-form'>
                   <div className='name-email'>
                             <input type='text' id='name' name='user_name'  placeholder="Full Name" required/>
                             <input type='text' id='email' name='user_email' placeholder="Email" required/>
                   </div>
                   <textarea name='message'  id='message'  placeholder="Message"/>

                   <button  type='submit' className='send-button'>Send Message</button>
                   <ToastContainer autoClose={3000}  theme="light"  progressStyle={{color:"green"}} toastStyle={{backgroundColor:"#292826",color:"white",border:"solid 1px #F7D060"}} bodyStyle={{color:"white"}} style={{color:"#0a0a0a",fontWeight:'bold'}}/>
                   <div className='other-method'>
                  <p>Or</p>
                  <div className='contact-buttons'>
                      <p>Contact on</p> <MyIcon onClick={()=>handleIconClick('https://www.fiverr.com/s/LyAkLo')}  className='fiverr-icon' style={{height:'3.5rem',width:'3.5rem' }}/>
                  </div>
                  
                </div>
             </div>
              </form>
             
        </section>
    )
}